import { capitalizeFirstLetter, scaleLookup } from './utilities'

import styles from './Legend.css'

export function Legend({ items, scale, getClass, marginLeft }) {
  return (
    <dl className={styles.component} style={{ '--margin-left': `${marginLeft}px` }}>
      {items?.map(x => (
        <dd key={x} className={styles.legendItem}>
          <div className={cx(styles.dot, getClass(x.key))} />

          <span className={styles.legendText}>
            <span>{capitalizeFirstLetter(x.key)}</span>
            {scale && <sup>{scaleLookup[scale]}</sup>}
          </span>
        </dd>
      ))}
    </dl>
  )
}
