import { chain, filterKeys, getMaxValueFromAccumulatedColumnValues, getYDomain, withAttributes, withCalls, withEventHandlers, withSelect } from '/features/dataVisualisation/utilities'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useIsomorphicLayoutEffect } from '@react-spring/web'
import { Legend } from './Legend'
import { getData } from './data'
import * as d3 from 'd3'

import mediaStyles from '/cssGlobal/media.css'
import styles from './BarChart.css'

const width = 640
const height = 400
const marginTop = 20
const marginRight = 20
const padding = 8

const marginBottomDesktop = 30
const marginBottomMobile = 60

const marginLeftDesktop = 30
const marginLeftMobile = 50

export function BarChart({ dataSet, xName, columns = [], yDomain: userDefinedYDomain, xDomain: userDefinedXDomain, scale = 1, deltaX = 1 }) {
  const isViewportMd = useMediaQuery(mediaStyles.breakpointMd)
  const marginBottom = isViewportMd ? marginBottomDesktop : marginBottomMobile
  const marginLeft = isViewportMd ? marginLeftDesktop : marginLeftMobile

  const { stacks, xValues, yValues, data } = getData({ dataSet, xName, columns, xDomain: userDefinedXDomain, deltaX })
  const yDomain = getYDomain({ yValues, overrides: userDefinedYDomain })
  const { x: scaleX, y: scaleY } = createScales(xValues, yDomain)
  const { svg, ref: svgRef, barClasses } = useGraph({ columns, scaleY })

  useIsomorphicLayoutEffect(
    () => {
      addScaleX({ svg, scaleX })
      addScaleY({ svg, scaleY })
      addLabels({ svg, data, scales: { scaleX, scaleY } })
      addBars({ svg, stacks, scales: { scaleX, scaleY }, barClasses })

      return () => {
        svgRef.current = null
      }
    },
    [xValues, yDomain, stacks]
  )

  return (
    <div className={styles.component}>
      <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`} />
      <Legend items={stacks} getClass={barClasses} {...{ marginLeft, scale }} />
    </div>
  )

  function addLabels({ svg, data, scales }) {
    const { scaleX: x, scaleY: y } = scales

    const ANIMATION_DISTANCE = 5
    const OFFSET = 10

    const rects = svg
      .append('g')
      .selectAll('rect')
      .data(data)
      .enter()
      .append('g')
      .attr('id', d => `label-${d[xName]}`)
      .attr('class', styles.labelGroup)

    const texts = rects
      .append('text')
      .text(d =>
        (getMaxValueFromAccumulatedColumnValues(filterKeys(d, k => k !== xName)) / scale).toFixed(2)
      )

    chain(
      texts,
      withAttributes({
        x: d => x(d[xName]) + x.bandwidth() / 2,
        y: x => safePlaceOnAxisY(x, value => value - (OFFSET * 2)),
        class: styles.hoverTitle
      })
    )

    chain(
      rects.append('circle'),
      withAttributes({
        cx: d => x(d[xName]) + x.bandwidth() / 2,
        cy: x => safePlaceOnAxisY(x, value => value - OFFSET),
        class: styles.hoverDotGraphic,
        r: 4,
      })
    )

    chain(
      rects.append('line'),
      withAttributes({
        x1: d => x(d[xName]) + x.bandwidth() / 2,
        x2: d => x(d[xName]) + x.bandwidth() / 2,
        y1: x => safePlaceOnAxisY(x, value => value - OFFSET),
        y2: x => placeY2(x),
        class: styles.hoverLineGraphic
      })
    )

    function getUpperBound(x) {
      return y(getMaxValueFromAccumulatedColumnValues(x))
    }

    function safePlaceOnAxisY(x, callback) {
      const yValues = filterKeys(x, (k) => k !== xName)
      const value = Object.entries(yValues)
        .reduce((result, [, x]) => result + x, 0)

      const result = value >= 0
        ? getUpperBound(yValues)
        : y(0)

      return callback(result)
    }

    function placeY2(x) {
      const yValues = filterKeys(x, (k) => k !== xName)
      const value = Object.entries(yValues)
        .reduce((result, [, x]) => result + x, 0)

      const offset = value > 0
        ? ANIMATION_DISTANCE
        : 0

      return yDomain.min >= 0
        ? y(yDomain.min) - padding - offset
        : y(0) - offset - 1
    }
  }

  function addBars({ svg, stacks, scales, barClasses }) {
    const { scaleX: x, scaleY: y } = scales

    const group = svg
      .append('g')
      .selectAll('rect')
      .data(stacks)
      .enter()
      .append('g')

    const firstIteration = chain(
      group,
      withAttributes({ class: d => barClasses(d.key) })
    )

    const bars = firstIteration
      .selectAll('rect')
      .data(d => d)
      .enter()
      .append('rect')
      .attr('id', d => d.data[xName])

    chain(
      bars,
      withAttributes({
        x: d => x(d.data[xName]),
        y: ([a, b]) => b <= 0 ? y(a) : y(b),
        height: ([a, b]) => {
          const hasPositiveMinY = yDomain.min > 0
          const isAboveLowerBound = (a + yDomain.min) === yDomain.min

          const offset = hasPositiveMinY && isAboveLowerBound
            ? y(Math.max(0, a - yDomain.min)) - y(Math.max(0, b - yDomain.min)) - padding
            : y(Math.max(0, a - yDomain.min)) - y(Math.max(0, b - yDomain.min))

          return Math.max(Math.abs(offset), 0)
        },
        width: x.bandwidth(),
        rx: 2,
      }),
      withEventHandlers({
        onMouseOver: d => getLabelNode(d.target.id).attr('data-is-hovered', true),
        onMouseLeave: d => getLabelNode(d.target.id).attr('data-is-hovered', false)
      })
    )
  }

  function getLabelNode(id) {
    return svg.select(`#label-${id}`)
  }

  function addScaleX({ svg, scaleX }) {
    const group = svg.append('g')

    chain(
      group,
      withAttributes({ transform: `translate(0, ${height - marginBottom})` }),
      withCalls([
        d3.axisBottom(scaleX).tickSize(6).tickSizeOuter(0),
        setStrokeWidth,
        setTickStyle
      ]),
      withSelect('text'),
      withAttributes({ class: styles.scaleX })
    )
  }

  function setTickStyle(x) {
    return chain(
      x.selectAll('.tick line'),
      withAttributes({ class: styles.svgScaleTick })
    )
  }

  function setStrokeWidth(x) {
    return chain(
      x.selectAll('.domain'),
      withAttributes({ class: styles.svgScaleStroke })
    )
  }

  function hideTicks(x) {
    return chain(
      x.selectAll('.tick line'),
      withAttributes({ stroke: 'transparent' })
    )
  }

  function addScaleY({ svg, scaleY }) {
    const group = svg.append('g')

    chain(
      group,
      withAttributes({ transform: `translate(${marginLeft}, 0)` }),
      withCalls([
        d3.axisLeft(scaleY).ticks(8).tickSizeOuter(0).tickFormat(y => String(Number(y) / scale)),
        setStrokeWidth,
        hideTicks
      ]),
      withSelect('text'),
      withAttributes({ class: styles.scaleY })
    )
  }

  function createScales(xValues, yDomain) {
    const x = d3
      .scaleBand()
      .domain(xValues)
      .range([0, width])
      .rangeRound([marginLeft, width - marginRight])
      .padding(0.2)

    const y = d3
      .scaleLinear()
      .domain([yDomain.min, yDomain.max])
      .range([height - marginTop - marginBottom, 0])
      .nice()
      .rangeRound([height - marginBottom, marginTop])

    return { x, y }
  }

  function useGraph({ columns, scaleY }) {
    const svgRef = React.useRef(null)

    const svg = d3.select(svgRef.current)

    const barClasses = d3
      .scaleOrdinal()
      .domain(columns)
      .range([
        styles.barA,
        styles.barB,
        styles.barC,
        styles.barD
      ])

    const lines = svg
      .selectAll('lines')
      .data(scaleY.ticks())
      .enter()
      .append('line')

    chain(
      lines,
      withAttributes({
        x1: marginLeft,
        x2: width - marginRight,
        y1: d => scaleY(d),
        y2: d => scaleY(d),
        class: styles.xAxisLine
      })
    )

    return {
      svg,
      ref: svgRef,
      barClasses
    }
  }
}
