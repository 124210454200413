import * as d3 from 'd3'

const AantalWoningenPerJaar = [
  { 'Jaar': 2012, 'Aantal woningen': 7386743, 'aantal toegevoegd': 62534, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2013, 'Aantal woningen': 7449298, 'aantal toegevoegd': 86014, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2014, 'Aantal woningen': 7535315, 'aantal toegevoegd': 52643, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2015, 'Aantal woningen': 7587964, 'aantal toegevoegd': 53336, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2016, 'Aantal woningen': 7641323, 'aantal toegevoegd': 44851, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2017, 'Aantal woningen': 7686178, 'aantal toegevoegd': 54807, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2018, 'Aantal woningen': 7740984, 'aantal toegevoegd': 73921, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2019, 'Aantal woningen': 7814911, 'aantal toegevoegd': 76872, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2020, 'Aantal woningen': 7891786, 'aantal toegevoegd': 74543, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2021, 'Aantal woningen': 7966331, 'aantal toegevoegd': 79250, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2022, 'Aantal woningen': 8045580, 'aantal toegevoegd': 79649, 'Aantal te kort': 0, 'Prognose': 0 },
  { 'Jaar': 2023, 'Aantal woningen': 8125229, 'aantal toegevoegd': 78819, 'Aantal te kort': 401000, 'Prognose': 0 },
  { 'Jaar': 2024, 'Aantal woningen': 0, 'aantal toegevoegd': 0, 'Aantal te kort': 0, 'Prognose': 8225229 },
  { 'Jaar': 2025, 'Aantal woningen': 0, 'aantal toegevoegd': 0, 'Aantal te kort': 0, 'Prognose': 8325229 },
  { 'Jaar': 2026, 'Aantal woningen': 0, 'aantal toegevoegd': 0, 'Aantal te kort': 0, 'Prognose': 8425229 },
  { 'Jaar': 2027, 'Aantal woningen': 0, 'aantal toegevoegd': 0, 'Aantal te kort': 0, 'Prognose': 8525229 },
  { 'Jaar': 2028, 'Aantal woningen': 0, 'aantal toegevoegd': 0, 'Aantal te kort': 0, 'Prognose': 8625229 },
  { 'Jaar': 2029, 'Aantal woningen': 0, 'aantal toegevoegd': 0, 'Aantal te kort': 0, 'Prognose': 8725229 },
  { 'Jaar': 2030, 'Aantal woningen': 0, 'aantal toegevoegd': 0, 'Aantal te kort': 0, 'Prognose': 8825229 }
]

const VeranderingNieuwbouwPerJaar = [
  {
    'Jaar': 1935,
    'Nieuwbouw': 45231,
    'Verandering nieuwbouw (in %)': 0,
    'HypotheekRente': 4.62,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0
  },
  {
    'Jaar': 1936,
    'Nieuwbouw': 30285,
    'Verandering nieuwbouw (in %)': 0,
    'HypotheekRente': 4.67,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0
  },
  {
    'Jaar': 1937,
    'Nieuwbouw': 29119,
    'Verandering nieuwbouw (in %)': 0,
    'HypotheekRente': 4.29,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0
  },
  {
    'Jaar': 1938,
    'Nieuwbouw': 38375,
    'Verandering nieuwbouw (in %)': 0,
    'HypotheekRente': 3.95,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0
  },
  {
    'Jaar': 1939,
    'Nieuwbouw': 36830,
    'Verandering nieuwbouw (in %)': 0,
    'HypotheekRente': 3.97,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0
  },
  {
    'Jaar': 1940,
    'Nieuwbouw': 17661,
    'Verandering nieuwbouw (in %)': 0,
    'HypotheekRente': 4.38,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0
  },
  {
    'Jaar': 1941,
    'Nieuwbouw': 16450,
    'Verandering nieuwbouw (in %)': 0,
    'HypotheekRente': 4.18,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0
  },
  {
    'Jaar': 1942,
    'Nieuwbouw': 8355,
    'Verandering nieuwbouw (in %)': -54,
    'HypotheekRente': 4.1,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.32
  },
  {
    'Jaar': 1943,
    'Nieuwbouw': 3228,
    'Verandering nieuwbouw (in %)': -66,
    'HypotheekRente': 4.02,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.13
  },
  {
    'Jaar': 1944,
    'Nieuwbouw': 675,
    'Verandering nieuwbouw (in %)': -84,
    'HypotheekRente': 4.07,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.15
  },
  {
    'Jaar': 1945,
    'Nieuwbouw': 389,
    'Verandering nieuwbouw (in %)': -91,
    'HypotheekRente': 4.05,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0
  },
  {
    'Jaar': 1946,
    'Nieuwbouw': 1593,
    'Verandering nieuwbouw (in %)': -49,
    'HypotheekRente': 4.01,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.11
  },
  {
    'Jaar': 1947,
    'Nieuwbouw': 9243,
    'Verandering nieuwbouw (in %)': 918,
    'HypotheekRente': 3.93,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.17
  },
  {
    'Jaar': 1948,
    'Nieuwbouw': 36391,
    'Verandering nieuwbouw (in %)': 2202,
    'HypotheekRente': 3.74,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.06
  },
  {
    'Jaar': 1949,
    'Nieuwbouw': 42791,
    'Verandering nieuwbouw (in %)': 631,
    'HypotheekRente': 3.74,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.07
  },
  {
    'Jaar': 1950,
    'Nieuwbouw': 47300,
    'Verandering nieuwbouw (in %)': 97,
    'HypotheekRente': 3.74,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.15
  },
  {
    'Jaar': 1951,
    'Nieuwbouw': 58666,
    'Verandering nieuwbouw (in %)': 34,
    'HypotheekRente': 4,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.24
  },
  {
    'Jaar': 1952,
    'Nieuwbouw': 54601,
    'Verandering nieuwbouw (in %)': 26,
    'HypotheekRente': 4.46,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.26
  },
  {
    'Jaar': 1953,
    'Nieuwbouw': 59597,
    'Verandering nieuwbouw (in %)': 8,
    'HypotheekRente': 4.19,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.07
  },
  {
    'Jaar': 1954,
    'Nieuwbouw': 68487,
    'Verandering nieuwbouw (in %)': 13,
    'HypotheekRente': 3.88,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.26
  },
  {
    'Jaar': 1955,
    'Nieuwbouw': 60819,
    'Verandering nieuwbouw (in %)': 13,
    'HypotheekRente': 3.79,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.48
  },
  {
    'Jaar': 1956,
    'Nieuwbouw': 68284,
    'Verandering nieuwbouw (in %)': 1,
    'HypotheekRente': 4,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.35
  },
  {
    'Jaar': 1957,
    'Nieuwbouw': 88397,
    'Verandering nieuwbouw (in %)': 21,
    'HypotheekRente': 4.88,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.11
  },
  {
    'Jaar': 1958,
    'Nieuwbouw': 89037,
    'Verandering nieuwbouw (in %)': 37,
    'HypotheekRente': 5.18,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.33
  },
  {
    'Jaar': 1959,
    'Nieuwbouw': 83632,
    'Verandering nieuwbouw (in %)': 10,
    'HypotheekRente': 4.9,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.05
  },
  {
    'Jaar': 1960,
    'Nieuwbouw': 83815,
    'Verandering nieuwbouw (in %)': -6,
    'HypotheekRente': 4.83,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.73
  },
  {
    'Jaar': 1961,
    'Nieuwbouw': 82687,
    'Verandering nieuwbouw (in %)': -4,
    'HypotheekRente': 4.78,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.1
  },
  {
    'Jaar': 1962,
    'Nieuwbouw': 78375,
    'Verandering nieuwbouw (in %)': -4,
    'HypotheekRente': 4.79,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.75
  },
  {
    'Jaar': 1963,
    'Nieuwbouw': 79523,
    'Verandering nieuwbouw (in %)': -5,
    'HypotheekRente': 4.81,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.15
  },
  {
    'Jaar': 1964,
    'Nieuwbouw': 100978,
    'Verandering nieuwbouw (in %)': 12,
    'HypotheekRente': 5.24,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.19
  },
  {
    'Jaar': 1965,
    'Nieuwbouw': 115027,
    'Verandering nieuwbouw (in %)': 37,
    'HypotheekRente': 5.75,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.18
  },
  {
    'Jaar': 1966,
    'Nieuwbouw': 121699,
    'Verandering nieuwbouw (in %)': 31,
    'HypotheekRente': 6.34,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.11
  },
  {
    'Jaar': 1967,
    'Nieuwbouw': 127433,
    'Verandering nieuwbouw (in %)': 15,
    'HypotheekRente': 6.58,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.47
  },
  {
    'Jaar': 1968,
    'Nieuwbouw': 122773,
    'Verandering nieuwbouw (in %)': 6,
    'HypotheekRente': 6.6,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.98
  },
  {
    'Jaar': 1969,
    'Nieuwbouw': 123117,
    'Verandering nieuwbouw (in %)': -1,
    'HypotheekRente': 7.05,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.28
  },
  {
    'Jaar': 1970,
    'Nieuwbouw': 117284,
    'Verandering nieuwbouw (in %)': -4,
    'HypotheekRente': 8.01,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.24
  },
  {
    'Jaar': 1971,
    'Nieuwbouw': 136595,
    'Verandering nieuwbouw (in %)': 3,
    'HypotheekRente': 8.32,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.97
  },
  {
    'Jaar': 1972,
    'Nieuwbouw': 154209,
    'Verandering nieuwbouw (in %)': 21,
    'HypotheekRente': 7.94,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1
  },
  {
    'Jaar': 1973,
    'Nieuwbouw': 157460,
    'Verandering nieuwbouw (in %)': 23,
    'HypotheekRente': 8.04,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.29
  },
  {
    'Jaar': 1974,
    'Nieuwbouw': 148389,
    'Verandering nieuwbouw (in %)': 5,
    'HypotheekRente': 9.62,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.35
  },
  {
    'Jaar': 1975,
    'Nieuwbouw': 123005,
    'Verandering nieuwbouw (in %)': -13,
    'HypotheekRente': 9.33,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.88
  },
  {
    'Jaar': 1976,
    'Nieuwbouw': 109195,
    'Verandering nieuwbouw (in %)': -24,
    'HypotheekRente': 8.78,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.74
  },
  {
    'Jaar': 1977,
    'Nieuwbouw': 113522,
    'Verandering nieuwbouw (in %)': -18,
    'HypotheekRente': 8.71,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.91
  },
  {
    'Jaar': 1978,
    'Nieuwbouw': 108255,
    'Verandering nieuwbouw (in %)': -4,
    'HypotheekRente': 8.31,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.14
  },
  {
    'Jaar': 1979,
    'Nieuwbouw': 90150,
    'Verandering nieuwbouw (in %)': -11,
    'HypotheekRente': 9.12,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.41
  },
  {
    'Jaar': 1980,
    'Nieuwbouw': 116493,
    'Verandering nieuwbouw (in %)': -7,
    'HypotheekRente': 10.15,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.4
  },
  {
    'Jaar': 1981,
    'Nieuwbouw': 120547,
    'Verandering nieuwbouw (in %)': 19,
    'HypotheekRente': 10.92,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.53
  },
  {
    'Jaar': 1982,
    'Nieuwbouw': 126211,
    'Verandering nieuwbouw (in %)': 19,
    'HypotheekRente': 9.96,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.25
  },
  {
    'Jaar': 1983,
    'Nieuwbouw': 114050,
    'Verandering nieuwbouw (in %)': 1,
    'HypotheekRente': 8.33,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.46
  },
  {
    'Jaar': 1984,
    'Nieuwbouw': 115765,
    'Verandering nieuwbouw (in %)': -7,
    'HypotheekRente': 8.33,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.63
  },
  {
    'Jaar': 1985,
    'Nieuwbouw': 101116,
    'Verandering nieuwbouw (in %)': -10,
    'HypotheekRente': 7.77,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.54
  },
  {
    'Jaar': 1986,
    'Nieuwbouw': 106416,
    'Verandering nieuwbouw (in %)': -10,
    'HypotheekRente': 7,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.19
  },
  {
    'Jaar': 1987,
    'Nieuwbouw': 113363,
    'Verandering nieuwbouw (in %)': 1,
    'HypotheekRente': 6.95,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -2.2
  },
  {
    'Jaar': 1988,
    'Nieuwbouw': 121868,
    'Verandering nieuwbouw (in %)': 13,
    'HypotheekRente': 6.9,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -2.04
  },
  {
    'Jaar': 1989,
    'Nieuwbouw': 114809,
    'Verandering nieuwbouw (in %)': 8,
    'HypotheekRente': 7.57,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.63
  },
  {
    'Jaar': 1990,
    'Nieuwbouw': 100888,
    'Verandering nieuwbouw (in %)': -8,
    'HypotheekRente': 8.72,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.19
  },
  {
    'Jaar': 1991,
    'Nieuwbouw': 86428,
    'Verandering nieuwbouw (in %)': -21,
    'HypotheekRente': 9.23,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.56
  },
  {
    'Jaar': 1992,
    'Nieuwbouw': 89728,
    'Verandering nieuwbouw (in %)': -18,
    'HypotheekRente': 8.84,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.49
  },
  {
    'Jaar': 1993,
    'Nieuwbouw': 87373,
    'Verandering nieuwbouw (in %)': -5,
    'HypotheekRente': 7.51,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.56
  },
  {
    'Jaar': 1994,
    'Nieuwbouw': 91244,
    'Verandering nieuwbouw (in %)': 1,
    'HypotheekRente': 7.25,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 1.79
  },
  {
    'Jaar': 1995,
    'Nieuwbouw': 97726,
    'Verandering nieuwbouw (in %)': 7,
    'HypotheekRente': 7.12,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.8
  },
  {
    'Jaar': 1996,
    'Nieuwbouw': 92769,
    'Verandering nieuwbouw (in %)': 7,
    'HypotheekRente': 6.25,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.64
  },
  {
    'Jaar': 1997,
    'Nieuwbouw': 96353,
    'Verandering nieuwbouw (in %)': 0,
    'HypotheekRente': 5.82,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.64
  },
  {
    'Jaar': 1998,
    'Nieuwbouw': 94578,
    'Verandering nieuwbouw (in %)': 0,
    'HypotheekRente': 5.56,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.65
  },
  {
    'Jaar': 1999,
    'Nieuwbouw': 82783,
    'Verandering nieuwbouw (in %)': -6,
    'HypotheekRente': 5.14,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.47
  },
  {
    'Jaar': 2000,
    'Nieuwbouw': 74774,
    'Verandering nieuwbouw (in %)': -17,
    'HypotheekRente': 5.88,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.42
  },
  {
    'Jaar': 2001,
    'Nieuwbouw': 77181,
    'Verandering nieuwbouw (in %)': -14,
    'HypotheekRente': 5.79,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.37
  },
  {
    'Jaar': 2002,
    'Nieuwbouw': 71143,
    'Verandering nieuwbouw (in %)': -6,
    'HypotheekRente': 5.33,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.87
  },
  {
    'Jaar': 2003,
    'Nieuwbouw': 64102,
    'Verandering nieuwbouw (in %)': -11,
    'HypotheekRente': 4.5,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.27
  },
  {
    'Jaar': 2004,
    'Nieuwbouw': 69832,
    'Verandering nieuwbouw (in %)': -10,
    'HypotheekRente': 4.15,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.16
  },
  {
    'Jaar': 2005,
    'Nieuwbouw': 71541,
    'Verandering nieuwbouw (in %)': 5,
    'HypotheekRente': 3.76,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.32
  },
  {
    'Jaar': 2006,
    'Nieuwbouw': 77103,
    'Verandering nieuwbouw (in %)': 11,
    'HypotheekRente': 4.38,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.94
  },
  {
    'Jaar': 2007,
    'Nieuwbouw': 85201,
    'Verandering nieuwbouw (in %)': 15,
    'HypotheekRente': 4.97,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.53
  },
  {
    'Jaar': 2008,
    'Nieuwbouw': 84174,
    'Verandering nieuwbouw (in %)': 14,
    'HypotheekRente': 5.34,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.11
  },
  {
    'Jaar': 2009,
    'Nieuwbouw': 87835,
    'Verandering nieuwbouw (in %)': 6,
    'HypotheekRente': 4.86,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.29
  },
  {
    'Jaar': 2010,
    'Nieuwbouw': 60556,
    'Verandering nieuwbouw (in %)': -12,
    'HypotheekRente': 4.52,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.76
  },
  {
    'Jaar': 2011,
    'Nieuwbouw': 62199,
    'Verandering nieuwbouw (in %)': -29,
    'HypotheekRente': 4.55,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.96
  },
  {
    'Jaar': 2012,
    'Nieuwbouw': 48668,
    'Verandering nieuwbouw (in %)': -25,
    'HypotheekRente': 4.27,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': 0.54
  },
  {
    'Jaar': 2013,
    'Nieuwbouw': 49311,
    'Verandering nieuwbouw (in %)': -20,
    'HypotheekRente': 3.78,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.25
  },
  {
    'Jaar': 2014,
    'Nieuwbouw': 45170,
    'Verandering nieuwbouw (in %)': -15,
    'HypotheekRente': 3.37,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.61
  },
  {
    'Jaar': 2015,
    'Nieuwbouw': 48381,
    'Verandering nieuwbouw (in %)': -5,
    'HypotheekRente': 2.93,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.7
  },
  {
    'Jaar': 2016,
    'Nieuwbouw': 54849,
    'Verandering nieuwbouw (in %)': 9,
    'HypotheekRente': 2.59,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.83
  },
  {
    'Jaar': 2017,
    'Nieuwbouw': 62982,
    'Verandering nieuwbouw (in %)': 26,
    'HypotheekRente': 2.41,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.09
  },
  {
    'Jaar': 2018,
    'Nieuwbouw': 66585,
    'Verandering nieuwbouw (in %)': 26,
    'HypotheekRente': 2.4,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.24
  },
  {
    'Jaar': 2019,
    'Nieuwbouw': 71548,
    'Verandering nieuwbouw (in %)': 17,
    'HypotheekRente': 2.27,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -1.16
  },
  {
    'Jaar': 2020,
    'Nieuwbouw': 69985,
    'Verandering nieuwbouw (in %)': 9,
    'HypotheekRente': 1.89,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.9
  },
  {
    'Jaar': 2021,
    'Nieuwbouw': 71221,
    'Verandering nieuwbouw (in %)': 2,
    'HypotheekRente': 1.7,
    'Hypotheekrente, laatste 2 jaar, vertraging 1 jaar': -0.6
  }
]

export const dataset = {
  AantalWoningenPerJaar,
  VeranderingNieuwbouwPerJaar,
}

export function getData({ dataSet, xName, columns, xDomain, deltaX = 1 }) {
  const filteredData = dataset[dataSet]
    ?.filter(x => (xDomain?.min ? x[xName] >= xDomain.min : true)
      && (xDomain?.max ? x[xName] <= xDomain.max : true)
    )
    ?.filter((_, idx) => idx % deltaX === 0) || []

  const data = filteredData.map(
    x => [xName, ...columns].reduce(
      (result, column) => {
        result[column] = x[column]
        return result
      },
      {}
    )
  )

  const yValues = filteredData.map(
    x => columns.reduce(
      (result, column) => {
        result[column] = x[column]
        return result
      },
      {}
    )
  )

  const xValues = data.map(x => x[xName])
  const stacks = d3.stack().keys(columns.filter(Boolean))(data)

  return { data, stacks, xValues, yValues, columns }
}
