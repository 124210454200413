import { chain, filterKeys, getDomain, getMaxValueFromAccumulatedColumnValues, withAttributes, withCalls, withEventHandlers, withSelect } from '/features/dataVisualisation/utilities'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useIsomorphicLayoutEffect } from '@react-spring/web'
import { Legend } from './Legend'
import { getData } from './data'
import * as d3 from 'd3'

import mediaStyles from '/cssGlobal/media.css'
import styles from './LineGraph.css'

const width = 640
const height = 400
const marginTop = 20
const marginRight = 20
const padding = 8

const marginBottomDesktop = 30
const marginBottomMobile = 100

const marginLeftDesktop = 30
const marginLeftMobile = 50

export function LineGraph({ dataSet, xName, yName, xDomain: userDefinedXDomain, yDomain: userDefinedYDomain, scale = 1 }) {
  const isViewportMd = useMediaQuery(mediaStyles.breakpointMd)
  const marginBottom = isViewportMd ? marginBottomDesktop : marginBottomMobile
  const marginLeft = isViewportMd ? marginLeftDesktop : marginLeftMobile

  const { xValues, yValues: rawYValues, data, stacks, columns } = getData({ xName, dataSet, xDomain: userDefinedXDomain, columns: [yName] })
  const yValues = rawYValues.map(x => x[yName])
  const yDomain = getDomain(yValues, { withMargin: 0.1, overrides: userDefinedYDomain })
  const xDomain = getDomain(xValues, { overrides: userDefinedXDomain })
  const { x: scaleX, y: scaleY } = createScales(xDomain, yDomain)
  const { svg, ref: svgRef, getLineClass } = useGraph({ columns, scaleY })

  useIsomorphicLayoutEffect(
    () => {
      addScaleX({ svg, scaleX })
      addScaleY({ svg, scaleY })
      addLabels({ svg, data, scales: { scaleX, scaleY } })
      addLine({ svg, scales: { scaleX, scaleY } })
      addCircle({ svg, scales: { scaleX, scaleY } })

      return () => {
        svgRef.current = null
      }
    },
    [data, stacks]
  )

  return (
    <div className={styles.component}>
      <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`} />
      <Legend items={stacks} getClass={getLineClass} {...{ marginLeft, scale }} />
    </div>
  )

  function addLabels({ svg, data, scales }) {
    const { scaleX: x, scaleY: y } = scales

    const rects = svg
      .append('g')
      .selectAll('rect')
      .data(data)
      .enter()
      .append('g')
      .attr('id', d => `label-${d[xName]}`)
      .attr('class', styles.labelGroup)

    const texts = rects
      .append('text')
      .text(d =>
        (getMaxValueFromAccumulatedColumnValues(filterKeys(d, k => k !== xName)) / scale).toFixed(2)
      )

    chain(
      texts,
      withAttributes({
        x: d => x(d[xName]),
        y: x => y((getMaxValueFromAccumulatedColumnValues(filterKeys(x, k => k !== xName)) / scale).toFixed(2)) - 20,
        class: styles.hoverTitle
      })
    )

    chain(
      rects.append('line'),
      withAttributes({
        x1: d => x(d[xName]),
        x2: d => x(d[xName]),
        y1: d => y(d[yName]),
        y2: () => height - marginBottom - padding,
        class: styles.hoverLineGraphic,
      })
    )
  }

  function addCircle({ svg, scales }) {
    const { scaleX: x, scaleY: y } = scales

    const circles = svg
      .append('g')
      .selectAll('rect')
      .data(data)
      .enter()
      .append('circle')
      .attr('id', d => d[xName])

    chain(
      circles,
      withAttributes({
        cx: d => x(d[xName]),
        cy: d => y(d[yName]),
        fill: 'black',
        r: 5,
      }),
      withEventHandlers({
        onMouseOver: x => getLabelNode(x.target.id).attr('data-is-hovered', true),
        onMouseLeave: x => getLabelNode(x.target.id).attr('data-is-hovered', false),
      })
    )
  }

  function addLine({ svg, scales }) {
    const { scaleX: x, scaleY: y } = scales

    const line = d3
      .line()
      .x(d => x(d[xName]))
      .y(d => y(d[yName]))
      .curve(d3.curveCardinal)

    chain(
      svg.append('path').datum(data),
      withAttributes({ d: line, class: d => cx(styles.lineGraphLine, getLineClass(d.key)) })
    )
  }

  function getLabelNode(id) {
    return svg.select(`#label-${id}`)
  }

  function addScaleX({ svg, scaleX }) {
    const group = svg.append('g')

    chain(
      group,
      withAttributes({ transform: `translate(0, ${height - marginBottom})` }),
      withCalls([
        d3.axisBottom(scaleX).tickSize(6).tickSizeOuter(0).tickFormat(y => String(y)),
        setStrokeWidth,
        setTickStyle
      ]),
      withSelect('text'),
      withAttributes({ class: styles.scaleX })
    )
  }

  function setTickStyle(x) {
    return chain(
      x.selectAll('.tick line'),
      withAttributes({ class: styles.svgScaleTick })
    )
  }

  function setStrokeWidth(x) {
    return chain(
      x.selectAll('.domain'),
      withAttributes({ class: styles.svgScaleStroke })
    )
  }

  function hideTicks(x) {
    return chain(
      x.selectAll('.tick line'),
      withAttributes({ stroke: 'transparent' })
    )
  }

  function addScaleY({ svg, scaleY }) {
    const group = svg.append('g')

    chain(
      group,
      withAttributes({ transform: `translate(${marginLeft}, 0)` }),
      withCalls([
        d3.axisLeft(scaleY).ticks(8).tickSizeOuter(0).tickFormat(y => String(Number(y) / scale)),
        setStrokeWidth,
        hideTicks
      ]),
      withSelect('text'),
      withAttributes({ class: styles.scaleY })
    )
  }

  function createScales(xDomain, yDomain) {
    const x = d3
      .scaleLinear()
      .domain([xDomain.min, xDomain.max])
      .range([marginLeft, marginLeft + (width - marginLeft - marginRight)])
      .clamp(true)

    const y = d3
      .scaleLinear([yDomain.min, yDomain.max], [(height - marginTop - marginBottom) + marginTop, marginTop])
      .clamp(true)

    return { x, y }
  }

  function useGraph({ columns, scaleY }) {
    const svgRef = React.useRef(null)

    const svg = d3.select(svgRef.current)

    const getLineClass = d3
      .scaleOrdinal()
      .domain(columns)
      .range([
        styles.lineA,
      ])

    const lines = svg
      .selectAll('lines')
      .data(scaleY.ticks())
      .enter()
      .append('line')

    chain(
      lines,
      withAttributes({
        x1: marginLeft,
        x2: width - marginRight,
        y1: d => scaleY(d),
        y2: d => scaleY(d),
        class: d => cx(d === 0 && styles.isZero, styles.xAxisLine)
      })
    )

    return {
      svg,
      ref: svgRef,
      getLineClass
    }
  }
}
