import { PortableText } from '@portabletext/react'

import { useReportError } from '/machinery/ReportError'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { ContainerMd } from '/features/buildingBlocks/Container'
import { ButtonLinkPrimary, ButtonLinkSecondary } from '/features/buildingBlocks/Button'
import { RevealingQuote, RevealingQuoteWithImage } from '/features/regionArticles/RevealingQuote'
import { ImageSlider } from '/features/article/buildingBlocks/ImageSlider'
import { Video } from '/features/article/buildingBlocks/Video'
import { AudioPlayerArticlePage } from '/features/gedicht/buildingBlocks/AudioPlayer'
import { LoopingVideoAll } from './article/pageOnly/LoopingVideo'
import { ParallaxImage } from '/features/buildingBlocks/ParallaxImage'
import { ZoomableImageArticle } from '/features/buildingBlocks/ZoomableImageArticle'
import { Crediter } from '/features/buildingBlocks/Crediter'
import { Author } from '/features/article/buildingBlocks/Author'
import { Position } from '/features/article/buildingBlocks/Position'
import { PhotoIconOverlay } from '/features/buildingBlocks/PhotoIconOverlay'
import { Caption } from '/features/buildingBlocks/Caption'
import { useWithGroupedBlocksType } from '/machinery/useWithGroupedBlocksType'
import { Poll } from '/features/pageOnly/Poll'

import { BarChart } from '/features/dataVisualisation/BarChart'
import { LineGraph } from '/features/dataVisualisation/LineGraph'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Content.css'

import arrowRight from '/images/icons/chevron-right.raw.svg'

const layoutComponents = {
  'wrapper': {
    page({ layoutClassName, template, children }) {
      const Container = ShouldRenderFullWidthOnPage({ template }) ? React.Fragment : ContainerMd
      return (
        <SharedSection dataX={template} {...{ layoutClassName }}>
          <Container> {children} </Container>
        </SharedSection>
      )
    },
  },
}

/**
 * @param {{
*   contentItems: any,
*   layoutClassName?: string,
*   styleId?: 'default' | 'snackable' | 'page' | 'conclusion' | 'none',
*   layoutStyleId?: 'default' | 'page' | 'snackable',
* }} props
*/
export function ContentSanity({ contentItems, styleId = 'default', layoutStyleId = 'default', layoutClassName = undefined }) {
  const contentWithGroupedBlocks = useWithGroupedBlocksType(contentItems ?? [])
  const reportError = useReportError()
  const components = getPortableTextComponents({ styleId })

  return (
    <div className={cx(styles.componentSanity, styles[`${styleId}Base`], styles[layoutStyleId], layoutClassName)}>
      {contentWithGroupedBlocks.map((x, key) => {
        switch (x._type) {
          case 'blocks': return <SanityPortableTextComponent key={key} value={x.blocks} {...{ components }} />
          case 'quote': return wrapSanityComponent(SharedQuote, { text: x.quote }, { key, template: 'quote', layoutClassName: styles.quoteLayout, styleId })
          case 'contentImageSlider': return wrapSanityComponent(ContentSharedImageSlider, { images: x.contentImages.map(x => ({ ...x, image: x.contentImage })) }, {
            key,
            layoutClassName: styles.imageSliderLayout,
            styleId,
            template: 'imageSlider',
          })

          case 'zoomableImage': return wrapSanityComponent(SharedZoomable, { image: x.image }, { key, template: 'zoomable', layoutClassName: styles.zoomableLayout, styleId })

          case 'barChart': return wrapSanityComponent(BarChart, {
            dataSet: x.dataSet,
            xName: 'Jaar',
            columns: x.columns,
            yDomain: x.yDomain,
            xDomain: x.xDomain,
            scale: x.yScale,
            deltaX: x.deltaX
          }, {
            key,
            template: 'chart',
            layoutClassName: styles.chartLayout,
            styleId
          })

          case 'lineGraph': return wrapSanityComponent(LineGraph, {
            dataSet: x.dataSet,
            xName: x.xName,
            xDomain: x.xDomain,
            yName: x.yName,
            yDomain: x.yDomain,
            scale: x.yScale,
          }, {
            key,
            template: 'chart',
            layoutClassName: styles.chartLayout, styleId
          })

          case 'contentImage': return wrapSanityComponent(SharedImage, {
            image: x.contentImage,
            caption: x.caption,
            photographer: x.photographer
          }, {
            key,
            template: 'image',
            styleId,
            layoutClassName: styles.imageLayout,
          })
          default: {
            reportError(new Error(`Unknown content type '${x._type}'`))
            return null
          }
        }
      })}
    </div>
  )
}

/**
 * @param {{
 *   contentItems: any,
 *   layoutClassName?: string,
 *   styleId?: 'default' | 'snackable' | 'page' | 'conclusion' | 'none',
 *   layoutStyleId?: 'default' | 'page' | 'snackable',
 * }} props
 */
export function ContentWoodwing({ contentItems, styleId = 'default', layoutStyleId = 'default', layoutClassName = undefined }) {
  const reportError = useReportError()
  const wrapper = layoutComponents.wrapper[layoutStyleId]

  return (
    <div className={cx(styles.componentWoodwing, styles[`${styleId}Base`], styles[layoutStyleId], layoutClassName)}>
      {contentItems.map((item, i) =>
        <React.Fragment key={i}>
          {renderContentItem(item, { styleId, reportError, wrapper })}
        </React.Fragment>
      )}
    </div>
  )
}

/**
 * @param {{
 *   item: any,
 *   layoutClassName?: string,
 *   styleId?: 'default' | 'snackable' | 'page' | 'none',
 * }} props
*/
export function ContentItemWoodwing({ item, styleId = 'default', layoutClassName = undefined }) {
  const reportError = useReportError()

  return renderContentItem(item, { styleId, reportError, layoutClassName })
}

function renderContentItem(item, { styleId, reportError, wrapper = undefined, layoutClassName = undefined }) {
  const { identifier } = item
  const template = determineTemplate(identifier, { reportError })
  layoutClassName = layoutClassName || styles[`${template}Layout`]

  return renderWrappedElement({ item, template, identifier, styleId, wrapper, reportError, layoutClassName })
}

function renderWrappedElement({ item, identifier, template, styleId, wrapper, reportError, layoutClassName }) {
  const element = renderContentElement(
    item,
    { styleId, reportError, template, layoutClassName: wrapper ? undefined : layoutClassName }
  )

  if (!wrapper) return element

  const Wrapper = wrapper
  return <Wrapper {...{ identifier, template, layoutClassName }}>{element}</Wrapper>
}

function renderContentElement(item, { styleId, template, reportError, layoutClassName }) {
  if (!item.content && ['audio'].includes(template)) return null

  switch (template) {
    case 'text': return (
      <SharedText text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'paragraphs': return (
      <WoodwingParagraphs paragraphs={item.paragraphs} {...{ styleId, layoutClassName }} />
    )
    case 'cta': return ( // TODO: Lars / Erwin - in the previous version the CallToAction component was used with title and description
      <WoodwingCta text={item.text} href={item.href} {...{ styleId, layoutClassName }} />
    )
    case 'intro': return (
      <WoodwingIntro paragraphs={item.paragraphs} {...{ styleId, layoutClassName }} />
    )
    case 'heading': return (
      <SharedHeading text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'subtitle': return (
      <WoodwingSubtitle text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'name': return (
      <Author name={item.text} {...{ layoutClassName }} />
    )
    case 'position': return (
      <Position position={item.text} {...{ layoutClassName }} />
    )
    case 'quote': return (
      <SharedQuote text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'quoteWithImage': return (
      <WoodwingQuoteWithImage
        quote={item.quote}
        image={item.image}
        caption={item.caption}
        photographer={item.photographer}
        {...{ styleId, layoutClassName }}
      />
    )
    case 'zoomable': return (
      <SharedZoomable image={item.image} {...{ styleId, layoutClassName }} />
    )
    case 'image': return (
      <SharedImage
        image={item.image}
        caption={item.caption}
        photographer={item.photographer}
        {...{ styleId, layoutClassName }}
      />
    )
    case 'imageSlider': return (
      <ContentSharedImageSlider images={item.images} {...{ styleId, layoutClassName }} />
    )
    case 'video': return (
      // TODO: Erik / Erwin - hier missen we nog velden: description, image
      // TODO: Lars - ik zag in de src van de test data een \", zetten wij die erin of is dat een fout bij Woodwing?
      <WoodwingVideo video={item.src} poster={item.image} description={item.description} {...{ styleId, layoutClassName }} />
    )

    case 'gif':
      return item.src && (
        <WoodwingGif
          src={item.src}
          description={item.description}
          orientation={item.orientation}
          {...{ layoutClassName }}
        />
      )

    case 'audio': return (
      // TODO: Lars - ik zag in de src van de test data een \", zetten wij die erin of is dat een fout bij Woodwing?
      <WoodwingAudio audio={item.content.src} {...{ styleId, layoutClassName }} />
    )
    case 'list': return (
      <WoodwingList items={item.items} {...{ styleId, layoutClassName }} />
    )
    case 'conclusion': return (
      <WoodwingConclusion
        title={item.title}
        introParagraphs={item.introParagraphs}
        contentItems={item.contentItems}
        {...{ styleId, layoutClassName }}
      />
    )
    case 'gedicht': return (
      <WoodwingGedicht lines={item.lines} {...{ styleId, layoutClassName }} />
    )
    case 'kader': return (
      <ContentWoodwingKader title={item.title} contentItems={item.contentItems} {...{ styleId, layoutClassName }} />
    )
    case 'crediter': return (
      <Crediter position={item.identifier} name={item.text} />
    )
    case 'poll': return (
      item.poll && <Poll issue={item.issue} poll={item.poll} {...{ layoutClassName }} />
    )
    case 'unknown': return null
    default: {
      // TODO: Erik - this can be removed as soon as we have everything implemented
      const identifier = template.split('-').slice(1).join('-')
      if (identifier) return (
        <p style={{ backgroundColor: 'red' }} className={layoutClassName}>
          [NOT IMPLEMENTED - identifier: {identifier}]
        </p>
      )

      reportError(new Error(`Unknown template '${template}'`))
      return null
    }
  }
}

function SharedTextBase({ text, styleId, className, layoutClassName = undefined }) {
  return (
    <span className={cx(className, styles[styleId], layoutClassName)}>
      {text}
    </span>
  )
}

function SanityIntroText({ text, styleId, layoutClassName = undefined }) {
  return <SharedTextBase className={styles.componentSanityIntroText} {...{ text, styleId, layoutClassName }} />
}

function SharedText({ text, styleId, layoutClassName = undefined }) {
  return <SharedTextBase className={styles.componentSharedText} {...{ text, styleId, layoutClassName }} />
}

function WoodwingSubtitle({ text, styleId, layoutClassName = undefined }) {
  return <SharedTextBase className={styles.componentWoodwingSubtitle} {...{ text, styleId, layoutClassName }} />
}

function WoodwingName({ text, styleId, layoutClassName = undefined }) {
  return <SharedTextBase className={styles.componentWoodwingName} {...{ text, styleId, layoutClassName }} />
}

function WoodwingPosition({ text, styleId, layoutClassName = undefined }) {
  return <SharedTextBase className={styles.componentWoodwingPosition} {...{ text, styleId, layoutClassName }} />
}

function WoodwingParagraphs({ paragraphs, styleId, layoutClassName = undefined }) {
  return (
    <WoodwingParagraphsBase
      className={styles.componentWoodwingParagraphs}
      {...{ paragraphs, layoutClassName, styleId }}
    />
  )
}

function WoodwingIntro({ paragraphs, styleId, layoutClassName = undefined }) {
  return (
    <WoodwingParagraphsBase
      className={styles.componentWoodwingIntro}
      {...{ paragraphs, layoutClassName, styleId }}
    />
  )
}

function WoodwingParagraphsBase({ paragraphs, className, layoutClassName, styleId }) {
  return paragraphs.map((paragraph, i) =>
    <p key={i} className={cx(className, styles[styleId], layoutClassName)}>
      <WoodwingParagraph {...{ paragraph }} />
    </p>
  )
}

function WoodwingParagraph({ paragraph }) {
  return paragraph.flatMap((content, i) => {
    const { type, lines } = content
    return (
      type === 'lines' ? <WoodwingLines key={i} {...{ lines }} /> :
      type === 'link' ? <WoodwingParagraphLink key={i} href={content.href} {...{ lines }} /> :
      null
    )
  })
}

function WoodwingParagraphLink({ href, lines }) {
  return (
    <a {...{ href }} className={styles.componentWoodwingParagraphLink} data-x='link-to-external'>
      <WoodwingLines {...{ lines }} />
    </a>
  )
}

function WoodwingLines({ lines }) {
  const linesWithBreaks = lines.flatMap((line, i) => [<br />, line]).slice(1)

  return linesWithBreaks.map((x, i) =>
    <React.Fragment key={i}>{x}</React.Fragment>
  )
}

function SharedHeading({ text, styleId, layoutClassName = undefined }) {
  return (
    <h2 className={cx(styles.componentSharedHeading, styles[styleId], layoutClassName)}>
      {text}
    </h2>
  )
}

function WoodwingCta({ text, href, styleId, layoutClassName = undefined }) {
  const components = {
    'snackable': ButtonLinkSecondary,
    'default': ButtonLinkPrimary,
  }

  const Component = components[styleId] || components.default

  return (
    <Component
      label={text}
      dataX='link'
      icon={arrowRight}
      {...{ href, layoutClassName }}
    />
  )
}


function SharedQuote({ text, layoutClassName = undefined }) {
  return <RevealingQuote quote={text} {...{ layoutClassName }} />
}

function WoodwingQuoteWithImage({ caption, image, photographer, quote, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentWoodwingQuoteWithImage, layoutClassName)}>
      <RevealingQuoteWithImage {...{ quote, image, layoutClassName }} />
      <div className={styles.captionContainer}>
        <PhotoIconOverlay
          layoutClassName={styles.captionLayout}
          renderContent={() =>
            <Caption caption={[photographer, caption].filter(Boolean).join(' - ')} />
          }
        />
      </div>
    </div>
  )
}

function SharedImage({ caption, image, photographer, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentSharedImage, layoutClassName)}>
      <ParallaxImage {...{ image, layoutClassName }} />
      <div className={styles.captionContainer}>
        <PhotoIconOverlay
          layoutClassName={styles.captionLayout}
          renderContent={() =>
            <Caption caption={[photographer, caption].filter(Boolean).join(' - ')} />
          }
        />
      </div>
    </div>
  )
}

function SharedZoomable({ image, layoutClassName = undefined }) {
  return <ZoomableImageArticle {...{ image, layoutClassName }} />
}

function ContentSharedImageSlider({ images, layoutClassName = undefined }) {
  return <ImageSlider images={images.map(({ image, caption, photographer }) => ({ image, caption, photographer }))} {...{ layoutClassName }} />
}

function WoodwingVideo({ video, poster, description, layoutClassName = undefined }) {
  return <Video src={video} {...{ layoutClassName, poster, description }} />
}

function WoodwingAudio({ audio, layoutClassName = undefined }) {
  return <AudioPlayerArticlePage src={audio} {...{ layoutClassName }} />
}

function WoodwingList({ items, layoutClassName, styleId }) {
  return (
    <ul className={cx(styles.componentWoodwingList, styles[styleId], layoutClassName)}>
      {items.map((item, i) =>
        <WoodwingListItem key={i} {...{ item, styleId }} />
      )}
    </ul>
  )
}

function SanityList({ children, styleId }) {
  return (
    <ul className={cx(styles.componentSanityList, styles[styleId])}>
      {children}
    </ul>
  )
}

function WoodwingGif({ src, orientation, description }) {
  const isURL = src.startsWith('http')
  if (!isURL) return null

  return <LoopingVideoAll {...{ src, orientation, description }} />
}

function WoodwingListItem({ item, styleId }) {
  return (
    <li className={cx(styles[styleId])}>
      <WoodwingParagraphs paragraphs={item.paragraphs} {...{ styleId }} />
    </li>
  )
}


function SanityListItem({ children, styleId }) {
  return (
    <li className={cx(styles[styleId])}>
      {children}
    </li>
  )
}

function WoodwingConclusion({ title, introParagraphs, contentItems, styleId, layoutClassName }) {
  return (
    <div className={cx(styles.componentWoodwingConclusion, styles[styleId], layoutClassName)}>
      <SharedHeading text={title} styleId='conclusion' />
      <WoodwingIntro paragraphs={introParagraphs} styleId='conclusion' />
      <ContentWoodwing {...{ contentItems }} styleId='conclusion' />
    </div>
  )
}

export function ContentWoodwingKader({ contentItems, title = undefined, styleId, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentWoodwingKader, styles[styleId], layoutClassName)}>
      {title && <SharedHeading text={title} {...{ styleId }} />}
      <ContentWoodwing styleId='default' {...{ contentItems }} />
    </div>
  )
}

function WoodwingGedicht({ lines, layoutClassName, styleId }) {
  return (
    <div className={cx(styles.componentWoodwingGedicht, styles[styleId], layoutClassName)}>
      {lines.map((line, i) =>
        <WoodwingPoemLine key={i} {...{ line, styleId }} layoutClassName={styles.poemLineLayout} />
      )}
    </div>
  )
}

function WoodwingPoemLine({ line, styleId, layoutClassName }) {
  return <p className={cx(styles[styleId], layoutClassName)}>{line}</p>
}

function SharedSection({ dataX, layoutClassName, children }) {
  return (
    <section data-x={dataX} className={layoutClassName}>
      {children}
    </section>
  )
}

function SanityPortableTextComponent({ value, components, layoutClassName = undefined }) {
  return (
    <section data-x='text-block' className={cx(layoutClassName)}>
      <PortableText {...{ value, components }} />
    </section>
  )
}

/** @returns {import('@portabletext/react').PortableTextProps['components']} */
function getPortableTextComponents({ styleId }) {
  return {
    list: {
      bullet: ({ children }) => <SanityWrapper template='list'><SanityList {...{ styleId }}>{children}</SanityList></SanityWrapper>,
    },
    listItem: (props) => <SanityListItem {...{ styleId }}>{props.children}</SanityListItem>,
    marks: {
      strong: ({ children }) => <strong>{children}</strong>,
      em: ({ children }) => <em>{children}</em>,
      underlined: ({ children }) => <u>{children}</u>,
      externalLink: ({ value, children }) => <a href={value.href}>{children}</a>,
    },
    block: {
      introduction: ({ children }) => (
        <SanityWrapper template='intro' layoutClassName={styles.sanityIntroLayout}>
          <SanityIntroText text={children} layoutClassName={styles.sanityIntroLayout} {...{ styleId }} />
        </SanityWrapper>),
      normal: ({ children }) => (
        <SanityWrapper template='text' layoutClassName={styles.sanityTextLayout}>
          <SharedText text={children} layoutClassName={styles.sanityTextLayout} {...{ styleId }} />
        </SanityWrapper>),
      heading: ({ children }) => (
        <SanityWrapper template='heading' layoutClassName={styles.sanityHeadingLayout}>
          <SharedHeading text={children} layoutClassName={styles.sanityHeadingLayout} {...{ styleId }} />
        </SanityWrapper>),
    },
  }
}


/**
 * @template T
 * @param {React.FC<T>} Component
 * @param {T & { key?: React.Key}} props
 * @param {{ key, template, layoutClassName, styleId }} options
 * @returns
 */
function wrapSanityComponent(Component, props, { key, template, layoutClassName, styleId }) {
  return (
    <SanityWrapper key={key} {...{ layoutClassName, template }}>
      <Component {...props} {...{ layoutClassName, styleId }} />
    </SanityWrapper>
  )
}

function SanityWrapper({ template, children, layoutClassName = undefined }) {
  const Container = ShouldRenderFullWidthOnPage({ template }) ? React.Fragment : ContainerMd
  return (
    <SharedSection dataX={template} {...{ layoutClassName }}>
      <Container> {children} </Container>
    </SharedSection>
  )
}

function ShouldRenderFullWidthOnPage({ template }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return ['quoteWithImage', 'imageSlider', !isViewportMd && 'kader'].includes(template) // names here should be the normalized names
}

function determineTemplate(identifier, { reportError }) {
  switch (identifier) {
    // TODO: Lars - Once Woodwing data is received take a second look at the mapping and what is required and what isn't
    // TODO: https://kaliber.atlassian.net/browse/RABOCO-120
    case 'photographer': // TODO: Erwin - implement display of photographer
    case 'author': // TODO: Erwin - implement display of author //TODO: Erik - I think the Author may be rendered in the tags component
      return 'crediter'
    case 'body':
      return 'paragraphs'
    case 'button':
      return 'cta'
    case 'intro':
      return 'intro'
    case 'subheading':
      return 'heading'
    case 'quote':
      return 'quote'
    case 'image-quote':
      return 'quoteWithImage'
    case 'image':
      return 'image'
    case 'kaliber-image-slider':
    case 'slideshow':
      return 'imageSlider'
    case 'embed-video':
      return 'video'
    case 'embed-gif':
      return 'gif'
    case 'embed-audio': // TODO: Lars - Ik kom bij audio veel varianten tegen met geen of een \" in de src, komt dat door ons of door Woodwing?
      return 'audio'
    case 'kaliber-list':
      return 'list'
    case 'conclusioncontainer':
      return 'conclusion'
    case 'framecontainer':
      return 'kader'
    case 'kaliber-poem':
      return 'gedicht'
    case 'position':
      return 'position'
    case 'poll':
      return 'poll'
    case 'name':
      return 'name'
    case 'subtitle':
      return 'subtitle'
    case 'zoomeble': // zoomeble -> zoomable
      return 'zoomable'
    // TODO: Erik - check if these exist
    case 'crediter':
    case 'fact-list':
    case 'description-list':
    case 'hero-image-secondary':
    case 'hero-image':
    default: {
      reportError(new Error(`Unknown content identifier '${identifier}'`))
      return 'unknown'
    }
  }
}
