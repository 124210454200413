import kebabCase from 'lodash/kebabCase'
import * as d3 from 'd3'
/**
 * @template T
 * @param {*} attributes
 * @returns {function(D3SVG<T>): D3SVG<T>}
 */
export function withAttributes(attributes = {}) {
  return svgNode => Object.entries(attributes).reduce(
    (result, [k, v]) => result.attr(k.match(/[0-9]/g) ? k : kebabCase(k), v),
    svgNode
  )
}

/**
 * @template T
 * @param {*} attributes
 * @returns {function(D3SVG<T>): D3SVG<T>}
 */
export function withEventHandlers(attributes = {}) {
  return svgNode => Object.entries(attributes).reduce(
    (result, [k, v]) => result.on(k.replace('on', '').toLowerCase(), v),
    svgNode
  )
}

/**
 * @template T
 * @typedef {(import('d3').Selection<SVGElement | import('d3').BaseType, any, any, T>)}
 */
export function withChain(operations = []) {
  return svgNode => operations.reduce((result, operation) => operation(result), svgNode)
}

/**
 * @template T
 * @typedef {(import('d3').Selection<SVGElement | import('d3').BaseType, any, any, T>)}
 */
export function withSelect(selector, operations = []) {
  return svgNode => {
    const selection = svgNode.selectAll(selector)
    return operations.reduce(
      (result, operation) => operation(result),
      selection
    )
  }
}

/**
 * @template T
 * @param {Array<(selection: D3SVG<T>) => void>} calls
 * @returns {function(D3SVG<T>): D3SVG<T>}
 */
export function withCalls(calls = []) {
  return svgNode => {
    calls.forEach(call => svgNode.call(call))
    return svgNode // Return the selection for chaining
  }
}

/**
 * @template T
 * @param  {T} element
 * @param  {(function(T): T)[]} stack
 * @returns {T}
 */
export function chain(element, ...stack) {
  return stack.reduce(
    (result, x) => {
      if (typeof x !== 'function')
        throw new Error('No function for the rest args ')
      return x(result)
    },
    element
  )
}

/**
 * @template T
 * @typedef {(import('d3').Selection<SVGElement | import('d3').BaseType, any, any, T>)} D3SVG
 */

export function filterKeys(o, fn) {
  return Object.fromEntries(
    Object.entries(o).filter(([k, v]) => fn(k, v, o))
  )
}

export function getMaxValueFromAccumulatedColumnValues(item) {
  const value = Object.entries(item)
    .reduce((result, [_, v]) => result + v, 0)

  // Assert type number to suppress linting warning
  return Number(value)
}

export function getDomain(values, { withMargin = 0, overrides = {} } = {}) {
  const max = Math.max(...values)
  const min = Math.min(...values)

  return {
    min: overrides?.min || min * (1 + withMargin),
    max: overrides?.max || max * (1 + withMargin)
  }
}


export function getYDomain({ yValues, overrides }) {
  return {
    min: overrides?.min || Math.min(...yValues.map(x => Object.values(x)).flat()),
    max: overrides?.max || d3.max(yValues || [], item =>
      getMaxValueFromAccumulatedColumnValues(item)
    )
  }
}

export function capitalizeFirstLetter(string) {
  const [firstLetter, ...rest] = string
  return `${firstLetter.toUpperCase()}${rest.join('')}`
}

export const scaleLookup = {
  1000000: `x1 miljoen`,
  1000: `x1 duizend`,
  100: `x1 honderd`,
  1: ``,
}
